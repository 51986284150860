import React, { useState, useEffect } from "react";
import * as Icons from "heroicons-react";
import { Toaster } from "react-hot-toast";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import { Link } from "react-router-dom";
import { notify } from "../Utility/Notify";
import { compareAsc, format } from "date-fns";
import {
  // useRtvesQuery,
  useRtvCountQuery,
  // useRtvPagenationQuery,
  useDeleteRtvMutation,
  useRtvByDateQuery,
} from "../../services/rtvApi";
import RtvView from "../Common/Modal/RtvView";
// import ReactPaginate from "react-paginate";
// import useInventory from "../Hooks/useInventory";
import axios from "axios";
import LoadingModal from "../Common/Modal/LoadingModal";
import DatePicker from "react-datepicker";

const Rtv = () => {
  const [deleteRtv] = useDeleteRtvMutation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let i = 1;
  const [rtv, setRtv] = useState([]);
  const [grnView, setGrnView] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [q, setQ] = useState("");
  const [totalRtv, setTotalRtv] = useState(0);
  const [loader, setLoader] = useState(true)
  const handleLoaderClose = () => setLoader(false);
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));

  const { data, isSuccess, isFetching, refetch } = useRtvByDateQuery({
    startDate, endDate
  });
  // const { data, isSuccess, isFetching, refetch } = useRtvPagenationQuery({
  //   page,
  //   size,
  //   q,
  // });
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";
  // const { updateInventoryInOnRTVDel } = useInventory();
  useEffect(() => {
    setRtv(data);
  }, [isSuccess, isFetching]);


  useEffect(() => {
    let total = 0;
    data?.map(rtv => {
      total = total + rtv?.total
    })
    setTotalRtv(total)
  }, [data, isSuccess]);


  // console.log("rtv", totalRtv)

  useEffect(() => {
    rtv ? setLoader(false) : setLoader(true);
  }, [rtv]);

  const handelDeleteGrn = async (id) => {
    try {
      let newIn = [];
      /// axios diye data from data get
      const result = await axios.get(`${BASE_URL}/rtv/${id}`);
      // console.log(result.data.products)
      if (result?.data?.products?.length > 0) {

        result?.data?.products?.map((pro) => {
          newIn = [
            ...newIn,
            {
              article_code: pro?.article_code,
              qty: pro?.qty,
              // priceId: pro?.priceId,
              name: pro?.name,
            },
          ];
        });
      }
      const confirm = window.confirm("Are you Sure? Delete this RTV?");

      if (confirm) {
        setLoader(true)
        const res = await deleteRtv(id);
        if (res) {
          // TODO::
          // // UPDATE INVENTORY
          // const inventory = await updateInventoryInOnRTVDel(newIn);
          // console.log(inventory)

          notify("RTV Deleted Successful!", "error");
          // add error hendaler for delete error
          console.log(res);
        } else {
          console.log("Delete Operation Canceled!");
          return;
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoader(false)
    }
  };

  const handleGrnView = (id) => {
    // console.log(id);
    setGrnView(id);
    setShow(true);
  };

  // search and Pagenation funcation
  const pageCountQuery = useRtvCountQuery();
  useEffect(() => {
    const { data } = pageCountQuery;
    setPageCount(data);
  }, [pageCountQuery]);

  const handleDataLimit = (e) => {
    setSize(parseInt(e.target.value));
    setPageNo(getPageNumber);
    refetch();
  };

  // console.log(pageCount, size);
  const getPageNumber = () => {
    const cont = Math.ceil(parseInt(pageCount) / parseInt(size));
  };

  const handleSearch = (e) => {
    setQ(e.target.value);
    refetch();
  };

  const handlePageClick = (data) => {
    setPage(parseInt(data.selected));
    setPageNo(getPageNumber);
    refetch();
  };

  return (
    <>
      <div>
        <div className="container-fluid ">
          <LoadingModal
            title={"Please Wait"}
            onShow={loader}
            handleClose={handleLoaderClose}
          ></LoadingModal>

          <div className="row">
            <div className="col-md-2">
              <SideBar></SideBar>
            </div>
            <div className="col-md-10">
              <Header title="Return To Vendor"></Header>
              <div className="row">
                <div className="col-md-6">
                  {/* Sort date range */}
                  <div className="date-picker d-flex mt-2 mb-2 align-items-center">
                    {/* <b>Start:</b> */}
                    <DatePicker
                      selected={new Date(startDate)}
                      className="form-control me-2"
                      onChange={(date) =>
                        setStartDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                    <span width="10px"></span>
                    {/* <b>End:</b> */}
                    <DatePicker
                      selected={new Date(endDate)}
                      className="form-control"
                      onChange={(date) =>
                        setEndDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                  </div>

                </div>
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <Link to="/rtv-create" className="btn btn-dark float-end m-3">
                    Create New RTV
                  </Link>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-md-12"></div> */}
                <div className="col-md-12">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Rtv NO</th>
                        <th>Supplier</th>
                        <th>Warehouse</th>
                        <th>Date</th>
                        <th>Rtv By</th>
                        <th>Item No</th>
                        <th>Total</th>
                        {/* <th>Status</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rtv?.length > 0 ? (
                        rtv?.map((item) => (
                          <tr key={item._id}>
                            <th>{i++}</th>
                            <td>{item?.rtvNo}</td>
                            <td>{item?.supplier?.company}</td>
                            <td>{item?.warehouse?.name}</td>
                            <td>
                              {item?.createdAt &&
                                format(new Date(item.createdAt), "MM/dd/yyyy")}
                            </td>
                            <td>{item?.userId?.name}</td>
                            <td>{(item.totalItem).toFixed(2)}</td>
                            {/* <td>{item.tax}</td> */}
                            <td>{(item.total).toFixed(2)}</td>
                            {/* <td>{item.is_full ? "Full" : "Partials"}</td> */}
                            <td>
                              <Icons.EyeOutline
                                onClick={() => handleGrnView(item._id)}
                                className="icon-eye me-1"
                                size={20}
                              ></Icons.EyeOutline>
                              <Link to={``}>
                              <Icons.PencilAltOutline className="icon-edit" size={20} ></Icons.PencilAltOutline>
                            </Link>
                              <Icons.TrashOutline
                                className="icon-trash"
                                onClick={() => handelDeleteGrn(item?._id)}
                                size={20}
                              ></Icons.TrashOutline>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr colSpan={9}>No Return Found</tr>
                      )}
                      {
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>Total :</td>
                          <td>{(totalRtv).toFixed(2)}</td>
                          <td></td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <Toaster position="bottom-right" />
            </div>
          </div>
        </div>
        <RtvView show={show} handleClose={handleClose} grn={grnView}></RtvView>
      </div>
    </>
  );
};

export default Rtv;
