import React, { useState } from "react";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import "./Purchase.css";
import * as Icons from "heroicons-react";
// import CsvImporter from "../Common/CsvImporter/CsvImporter";
import { notify } from "../Utility/Notify";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import PurchaseView from "../Common/Modal/PurchaseView";
import { compareAsc, format } from "date-fns";
import {
  // usePurchasesQuery,
  // useDeletePurchaseMutation,
  usePurchaseByDateQuery,
  useUpdatePurchaseStatusMutation,
} from "../../services/purchasApi";
// import LoadingModal from "../Common/Modal/LoadingModal";
// import { Spinner } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import { MaterialReactTable } from "material-react-table";

const Purchase = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  // const [deletePurchase] = useDeletePurchaseMutation();
  const [deletePurchaseStatus] = useUpdatePurchaseStatusMutation();
  // const purchases = usePurchasesQuery();
  const { data, isSuccess, refetch } = usePurchaseByDateQuery({
    startDate,
    endDate,
  });
  console.log("po", data);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);
  const [loading, setLoading] = useState(true);

  let i = 1;
  const [purchase, setPurchase] = useState([]);
  const [purchaseView, setPurchaseView] = useState([]);
  const [totalPurchase, setTotalPurchase] = useState(0);

  // console.log("Purchese View", purchaseView);

  useEffect(() => {
    data?.length > 0 ? setPurchase(data) : setPurchase([]);
  }, [data, isSuccess]);

  useEffect(() => {
    let total = 0;
    data?.map((purchase) => {
      total =
        total +
        parseFloat(purchase?.total) -
        (purchase?.discount ? parseFloat(purchase?.discount) : 0) +
        (purchase?.shipping_cost ? parseFloat(purchase?.shipping_cost) : 0);
    });
    if (data?.length > 0) {
      setLoading(false);
      setTotalPurchase(total);
      setPurchase(data);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    isSuccess ? setLoader(false) : setLoader(true);
  }, [data, isSuccess]);

  const deleteHandler = async (id) => {
    try {
      const confirm = window.confirm("Are you Sure? Delete this Purchase?");
      if (confirm) {
        setLoader(true);
        const res = await deletePurchaseStatus({
          poNo: id,
          status: "Canceled",
        });
        // const res = await deletePurchase(id);
        if (res) {
          // TODO::
          notify("Purchase Deleted Successful!", "success");
          // add error hendaler for delete error
          console.log(res);
          refetch();
        } else {
          console.log("Delete Operation Canceled by Purchase!");
          return;
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const handlePurchaseView = (id) => {
    // send purchese id to purchese view modal
    setPurchaseView(id);
    setShow(true);
  };
  const handlePurchaseUpdate = (purchase) => {
    if (purchase.status === "Pending") {
      console.log(purchase._id);
      navigate(`/purchase-update/${purchase._id}`);
    } else {
      notify("This Purchase Can not be Modified", "error");
    }
  };

  // console.log(purchase);

  const columns = [
    { accessorKey: "poNo", header: "Invoice ID" },
    { accessorKey: "date", header: "Date", size: 120 },
    { accessorKey: "user", header: "User", size: 30 },
    { accessorKey: "supplier", header: "Vendor", size: 30 },
    { accessorKey: "totalItem", header: "Items", size: 60 },
    { accessorKey: "total", header: "Total", size: 30 },
    { accessorKey: "discount", header: "Discount", size: 30 },
    { accessorKey: "grossTotal", header: "Gross Total", size: 30 },
    { accessorKey: "status", header: "Status", size: 30 },
  ];

  const materialReactTableContainer = {
    overflow: "visible",
    position: "relative", // If needed
  };
  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Purchase"></Header>
            <div className="row">
              <div className="col-md-6"></div>

              <div className="col-md-12"></div>
              <div className="col-md-12">
                <div>
                  <MaterialReactTable
                    className={materialReactTableContainer}
                    columns={columns}
                    data={purchase}
                    state={{
                      isLoading: loading,
                    }}
                    // enableGrouping //Aggration
                    initialState={{
                      density: "compact",
                      pagination: {
                        pageIndex: 0,
                        pageSize: 100, // Rows per page
                      },
                    }}
                    enableStickyHeader //Enable Sticky Header
                    enableRowNumbers //Enable Row Numbers
                    enableRowActions //Enable Row Actions
                    positionActionsColumn="last" // Add action Column to the end
                    renderRowActions={({ row }) => (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          gap: "0.5rem",
                        }}
                      >
                        {row?.original?.status === "Pending" && (
                          <Link to={`/create-grn/${purchase._id}`}>
                            <Icons.ArchiveOutline
                              className="icon-edit"
                              size={20}
                            ></Icons.ArchiveOutline>
                          </Link>
                        )}
                        {row?.original?.status === "Pending" && (
                          <Icons.PencilAltOutline
                            onClick={() => handlePurchaseUpdate(row?.original)}
                            className="icon-eye me-1"
                            size={20}
                          ></Icons.PencilAltOutline>
                        )}

                        <Icons.EyeOutline
                          onClick={() => handlePurchaseView(row?.original?._id)}
                          className="icon-eye me-1"
                          size={20}
                        ></Icons.EyeOutline>
                        {row?.original?.status === "Pending" && (
                          <Icons.TrashOutline
                            className="icon-trash"
                            onClick={() => deleteHandler(row?.original?._id)}
                            size={20}
                          ></Icons.TrashOutline>
                        )}
                      </div>
                    )}
                    renderTopToolbarCustomActions={({ table }) => (
                      <div className="date-picker d-flex align-items-center justify-content-between">
                        {/* <b>Start:</b> */}
                        <input
                          type="date"
                          className="form-control me-2"
                          // value={selectedDate}
                          value={startDate}
                          // onChange={handleDateChange}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setStartDate(e.target.value);
                          }}
                          style={{ marginRight: "1rem" }} // Add some spacing
                        />

                        {/* <b>End:</b> */}
                        <input
                          type="date"
                          className="form-control me-2"
                          // value={selectedDate}
                          value={endDate}
                          // onChange={handleDateChange}
                          onChange={(e) => setEndDate(e.target.value)}
                          style={{ marginRight: "1rem" }} // Add some spacing
                        />
                        {/* <DatePicker
                      selected={new Date(endDate)}
                      className="form-control"
                      style={{ zIndex: "1300" }}
                      onChange={(date) =>
                        setEndDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    /> */}
                        <div class="btn-group d-grid gap-1" role="group">
                          <Link
                            to="/purchase-createSearch"
                            className="btn btn-dark btn-md btn-block float-end m-3"
                          >
                            CreateNewPurchase
                          </Link>
                        </div>
                      </div>
                      // </Box>
                    )}
                  />
                </div>
              </div>
            </div>
            <Toaster position="bottom-right" />
          </div>
        </div>
      </div>
      <PurchaseView
        show={show}
        handleClose={handleClose}
        purchase={purchaseView}
      ></PurchaseView>
    </div>
  );
};

export default Purchase;
