import React, { useState } from "react";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import "./Purchase.css";
import * as Icons from "heroicons-react";
// import CsvImporter from "../Common/CsvImporter/CsvImporter";
import { notify } from "../Utility/Notify";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect } from "react";
// import PurchaseView from "../Common/Modal/PurchaseView";
import { format, isToday } from "date-fns";
// import useInventory from "../Hooks/useInventory";
import GrnView from "../Common/Modal/GrnView";
import {
  // useGrnsQuery,
  // useGrnPagenationQuery,
  useGrnCountQuery,
  useDeleteGrnMutation,
  useGrnByDateQuery,
} from "../../services/grnApi";
// import ReactPaginate from "react-paginate";
// import { Spinner } from "react-bootstrap";
// import LoadingModal from "../Common/Modal/LoadingModal";
// import DatePicker from "react-datepicker";
import { MaterialReactTable } from "material-react-table";
import { CSVLink } from "react-csv";

function isCurrentDate(dbDate) {
  const result = isToday(new Date(dbDate));
  // console.log(result);
  return result;
}

const GRN = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));

  const [deleteGrn] = useDeleteGrnMutation();
  const [show, setShow] = useState(false);
  // const [delProducts, setDelProducts] = useState([]);
  // const { updateInventoryOUTOnGRNDel } = useInventory();
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [loader, setLoader] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [q, setQ] = useState("");
  const [totalGrn, setTotalGrn] = useState(0);
  // const [totalGrn, setTotalGrn] = useState(0);
  const [shippingCostTotal, setShippingCostTotal] = useState(0);
  const [grnTotal, setGrnTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [exportCSV, setExportCSV] = useState([]);

  const [loading, setLoading] = useState(true);
  // const handleLoaderClose = () => setLoader(false);

  let i = 1;
  const [grn, setGrn] = useState([]);
  const [grnView, setGrnView] = useState([]);
  // console.log(startDate, endDate);
  const { data, isSuccess, isFetching, refetch } = useGrnByDateQuery({
    startDate,
    endDate,
  });
  // const [updateInventoryOUTOnGRNDel] = useInventory;

  // console.log('Data ', data)

  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";

  useEffect(() => {
    if (data?.length > 0) {
      setGrn(data);
      setLoading(false);
    }
  }, [data, isSuccess, isFetching]);

  useEffect(() => {
    let totalGrnSum = 0;
    let discountTotalSum = 0;
    let shippingCostTotalSum = 0;
    let grandTotal = 0;

    data?.forEach((grnItem) => {
      const grnAmount = parseFloat(grnItem.total) || 0;
      const discount = parseFloat(grnItem.discount) || 0;
      const shippingCost = parseFloat(grnItem.shipping_cost) || 0;

      totalGrnSum += grnAmount;
      discountTotalSum += discount;
      shippingCostTotalSum += shippingCost;
      grandTotal += grnAmount - discount + shippingCost;
    });

    setGrnTotal(totalGrnSum.toFixed(2));
    setDiscountTotal(discountTotalSum.toFixed(2));
    setShippingCostTotal(shippingCostTotalSum.toFixed(2));
    setTotalGrn(grandTotal.toFixed(2));
  }, [data, isSuccess]);

  useEffect(() => {
    let total = 0;

    data?.forEach((grn) => {
      const grnTotal = parseFloat(grn?.total) || 0;
      const discount = grn?.discount ? parseFloat(grn.discount) : 0;
      const shippingCost = grn?.shipping_cost
        ? parseFloat(grn.shipping_cost)
        : 0;

      total += grnTotal - discount + shippingCost;
    });

    setTotalGrn(total);
  }, [data, isSuccess]);

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  useEffect(() => {
    if (data?.length > 0) {
      setGrn(data);
      setExportCSV(data);
    }
  }, [isSuccess, data]);

  // useEffect(() => {
  //   grn > 0 ? setLoading(false) : setLoading(true);
  // }, [grn]);
  // console.log(purchase)
  // console.log(grn);

  // const handelDeleteGrn = async (id) => {
  //   try {
  //     let newIn = [];
  //     /// axios diye data from data get
  //     const result = await axios.put(`${BASE_URL}/grn/${id}`);
  //     // console.log(result.data.products);
  //     if (result?.data?.products?.length > 0) {
  //       result?.data?.products?.map((pro) => {
  //         newIn = [
  //           ...newIn,
  //           {
  //             article_code: pro?.article_code,
  //             qty: pro?.qty,
  //             priceId: pro?.priceId,
  //             name: pro?.name,
  //           },
  //         ];
  //       });

  //       // setDelProducts(newIn);
  //       // console.log(newIn)
  //     }
  //     const confirm = window.confirm("Are you Sure? Delete this GRN?");

  //     if (confirm) {
  //       // setLoader(true);
  //       const res = await deleteGrn(id);
  //       if (res) {
  //         // console.log(delProducts);

  //         // // // UPDATE INVENTORY
  //         // const inventory = await updateInventoryOUTOnGRNDel(newIn);
  //         // console.log(inventory);
  //         // if(inventory){}
  //         // console.log(newIn);
  //         // console.log(inventory?.data);
  //         notify("GRN Deleted Successful!", "success");

  //         // add error hendaler for delete error
  //         // console.log(res);
  //         // } else {
  //         //   console.log("Delete Operation Canceled by Purchas!");
  //         //   return;
  //       } else {
  //         // setDelProducts([])
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     // setLoader(false);
  //   }
  // };

  const handelDeleteGrn = async (data) => {
    try {
      const confirm = window.confirm("Are you Sure? Delete this GRN?");

      if (confirm) {
        setLoader(true);
        const res = await deleteGrn(data);
        if (res) {
          refetch();
          notify("GRN Deleted Successful!", "success");
        } else {
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const handleGrnView = (id) => {
    // console.log(id);
    setGrnView(id);
    setShow(true);
  };
  const handlePurchaseUpdate = (id) => {
    // console.log(id);
    setGrnView(id);
    setShow(true);
  };

  const pageCountQuery = useGrnCountQuery();
  useEffect(() => {
    const { data } = pageCountQuery;
    setPageCount(data);
  }, [pageCountQuery]);

  const handleDataLimit = (e) => {
    setSize(parseInt(e.target.value));
    setPageNo(getPageNumber);
    refetch();
  };

  // console.log(pageCount, size);
  const getPageNumber = () => {
    const cont = Math.ceil(parseInt(pageCount) / parseInt(size));
  };

  const handleSearch = (e) => {
    setQ(e.target.value);
    refetch();
  };

  const handlePageClick = (data) => {
    setPage(parseInt(data.selected));
    setPageNo(getPageNumber);
    refetch();
  };

  const columns = [
    { accessorKey: "refNo", header: "PO/TPN" },
    { accessorKey: "grnNo", header: "GRN NO" },
    { accessorKey: "date", header: "Date", size: 120 },
    { accessorKey: "user", header: "User", size: 30 },
    { accessorKey: "supplier", header: "Vendor", size: 30 },
    { accessorKey: "totalItem", header: "Items", size: 60 },
    { accessorKey: "total", header: "Total", size: 30 },
    { accessorKey: "shipping_cost", header: "Shipping Cost", size: 30 },
    { accessorKey: "discount", header: "Discount", size: 30 },
    {
      accessorFn: (row) =>
        (
          parseFloat(row?.total) -
          (row?.discount ? parseFloat(row?.discount) : 0) +
          (row?.shipping_cost ? parseFloat(row?.shipping_cost) : 0)
        ).toFixed(2),
      header: "Net Total",
      size: 30,
    },
    { accessorKey: "status", header: "Status", size: 30 },
  ];

  const headers = [
    { label: "Ref No", key: "refNo" },
    { label: "GRN No", key: "grnNo" },
    { label: "Date", key: "date" },
    { label: "User", key: "user" },
    { label: "Supplier", key: "supplier" },
    { label: "Gross Total", key: "grossTotal" },
    { label: "Total Item", key: "totalItem" },
    { label: "Total", key: "total" },
    { label: "Shipping Cost", key: "shipping_cost" },
    { label: "Discount", key: "discount" },
    { label: "Status", key: "status" },
  ];

  const materialReactTableContainer = {
    overflow: "visible",
    position: "relative", // If needed
  };

  console.log("GRN DATA::>", grn);

  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Goods Receive Note"></Header>

            <div className="row">
              <div className="col-md-12"></div>
              <div className="col-md-12">
                <div>
                  <MaterialReactTable
                    className={materialReactTableContainer}
                    columns={columns}
                    data={grn}
                    state={{
                      isLoading: loading,
                    }}
                    // enableGrouping //Aggregation
                    initialState={{
                      density: "compact",
                      pagination: {
                        pageIndex: 0,
                        pageSize: 100, // Rows per page
                      },
                    }}
                    enableStickyHeader //Enable Sticky Header
                    enableRowNumbers //Enable Row Numbers
                    enableRowActions //Enable Row Actions
                    positionActionsColumn="last" // Add action Column to the end
                    renderRowActions={({ row }) => (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          gap: "0.5rem",
                        }}
                      >
                        {row?.original?.status === "Pending" && (
                          <Icons.PencilAltOutline
                            onClick={() =>
                              handlePurchaseUpdate(row?.original?._id)
                            }
                            className="icon-eye me-1"
                            size={20}
                          ></Icons.PencilAltOutline>
                        )}
                        <Icons.EyeOutline
                          onClick={() => handleGrnView(row?.original?._id)}
                          className="icon-eye me-1"
                          size={20}
                        ></Icons.EyeOutline>
                        {isCurrentDate(row?.original?.date) && (
                          <Icons.TrashOutline
                            className="icon-trash"
                            onClick={() =>
                              handelDeleteGrn({
                                _id: row?.original?._id,
                                poNo: row?.original?.refNo,
                              })
                            }
                            size={20}
                          />
                        )}
                      </div>
                    )}
                    renderTopToolbarCustomActions={({ table }) => (
                      <div className="date-picker d-flex align-items-center justify-content-between">
                        {/* <b>Start:</b> */}
                        <input
                          type="date"
                          className="form-control me-2"
                          // value={selectedDate}
                          value={startDate}
                          // onChange={handleDateChange}
                          onChange={(e) => {
                            // console.log(e.target.value);
                            setStartDate(e.target.value);
                          }}
                          style={{ marginRight: "1rem" }} // Add some spacing
                        />

                        {/* <b>End:</b> */}
                        <input
                          type="date"
                          className="form-control me-2"
                          // value={selectedDate}
                          value={endDate}
                          // onChange={handleDateChange}
                          onChange={(e) => setEndDate(e.target.value)}
                          style={{ marginRight: "1rem" }} // Add some spacing
                        />

                        <div class="btn-group d-grid gap-1" role="group">
                          <Link
                            to="/grn-create"
                            className="btn btn-dark btn-md btn-block float-end m-3"
                          >
                            CreateNewGrn
                          </Link>
                        </div>
                        <div class="btn-group d-grid gap-1" role="group">
                          <div class="col">
                            <CSVLink
                              className="btn btn-dark btn-block"
                              data={exportCSV}
                              asyncOnClick={true}
                              headers={headers}
                              filename={`Pharmacy Sales from [${startDate} to ${endDate}].csv`}
                            >
                              {exportCSV?.length === 0
                                ? `LoadingCsv...`
                                : "ExportSales"}
                            </CSVLink>
                          </div>
                        </div>
                      </div>
                      // </Box>
                    )}
                  />
                  <div className="container-fluid row py-4 text-end">
                    <div className="col-4"></div>
                    <div className="col-2">
                      <strong>GRN Total:</strong> {grnTotal}
                    </div>
                    <div className="col-2">
                      <strong>Shipping Cost Total:</strong> {shippingCostTotal}
                    </div>
                    <div className="col-2">
                      <strong>Discount Total:</strong> {discountTotal}
                    </div>
                    <div className="col-2">
                      <strong>Net Total:</strong> {totalGrn?.toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Toaster position="bottom-right" />
          </div>
        </div>
      </div>
      <GrnView show={show} handleClose={handleClose} grn={grnView}></GrnView>
    </div>
  );
};

export default GRN;
