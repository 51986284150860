import React, { useEffect, useState } from "react";
import { Toast, Card, Table } from "react-bootstrap";
import Header from "../../Common/Header/Header";
import SideBar from "../../Common/SideBar/SideBar";
import * as Icons from "heroicons-react";
import LineChart from "./Chart/LineChart";
// import PieChart from "./Chart/PieChart";
// import Select from "react-select";
import DatePicker from "react-datepicker";

import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import {
  useSaleFootfallQuery,
  useSalesPointSpentQuery,
  useSalesWeeklyQuery,
  useSaleTotalQuery,
} from "../../../services/saleApi";
import {
  useTodayGrnsQuery,
  useWeeklyGrnsQuery,
} from "../../../services/grnApi";
import { useTodayDamagesQuery } from "../../../services/damageApi";
import subDays from "date-fns/subDays";
import { Form } from "react-bootstrap";
import WareHouseDW from "../../Common/CustomSelect/WareHouseDW";

const Dashboard = () => {
  // const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  // const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const {
    data: total,
    error,
    isLoading,
    isFetching,
    isSuccess,
    refetch,
  } = useSaleTotalQuery({
    startDate: startDate,
    endDate: endDate,
  });

  console.log(total);

  const { data: footfall } = useSaleFootfallQuery({
    startDate: startDate,
    endDate: endDate,
  });
  const { data: pointSpent } = useSalesPointSpentQuery();
  // console.log({ startDate: "10-01-2022", endDate: "10-02-2022" });
  //{ data, error, isLoading, isFetching, isSuccess, refetch }

  useEffect(() => {
    // console.log(total);
  }, total?.isSuccess);

  // console.log(startDate, endDate, footfall, total);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const { data: weeklySale, isSuccess: isSuccessSale } = useSalesWeeklyQuery();
  // const { data: weeklyPurchase, isSuccess: isSuccessPurchase } = useWeeklyPurchasesQuery();
  const { data: weeklyGRN, isSuccess: isSuccessGRN } = useWeeklyGrnsQuery();
  const { data: todayTotalGRN, isSuccess: isSuccessTodayGRN } =
    useTodayGrnsQuery();

  const { data: todayTotalDamage, isSuccess: isSuccessTodayDamage } =
    useTodayDamagesQuery();
  // const [weekDates, setWeekDates] = useState([])
  const [weekSales, setWeekSales] = useState(0);
  // const [weekPurchase, setWeekPurchase] = useState([])
  const [weekGRN, setWeekGRN] = useState([]);
  const [lossProfit, setLossProfit] = useState(0);
  const [todaySale, setTodaySale] = useState(0);
  const [todayGrn, setTodayGrn] = useState(0);
  const [todayDamage, setTodayDamage] = useState(0);
  useEffect(() => {
    // console.log(total);
    if (total?.length > 0) {
      setTodaySale(total.total);
    }
    // console.log(todayTotalGRN);
    if (todayTotalGRN?.length > 0) {
      setTodayGrn(todayTotalGRN[0]?.total);
    }
    // console.log(todayTotalDamage);
    if (todayTotalDamage?.length > 0) {
      setTodayDamage(todayTotalDamage[0]?.total);
    }
  }, [
    isSuccess,
    total,
    isSuccessTodayGRN,
    todayTotalGRN,
    todayTotalDamage,
    isSuccessTodayDamage,
  ]);
  console.log("total", total);
  console.log("GRN::>",todayGrn);
  console.log("Sale",todaySale);

  useEffect(() => {
    const total = parseFloat(todaySale) - parseFloat(todayGrn);
    setLossProfit(total);
  }, [todaySale, todayGrn]);

  useEffect(() => {
    let g = 0;
    if (weeklyGRN?.length > 0) {
      weeklyGRN?.slice().map((grn) => {
        g = g + grn.total;
      });
      setWeekGRN(g);
    }
    // console.log(dates)
  }, [weeklyGRN, isSuccessGRN]);

  useEffect(() => {
    let sales = 0;
    if (weeklySale?.length > 0) {
      weeklySale?.slice().map((sale) => {
        sales = sales + sale.grossTotalRound;
      });
      setWeekSales(sales);
    }
  }, [weeklySale, isSuccessSale]);

  // console.log(weekSales);
  // console.log(weekGRN);

  const handleSelectDay = (day) => {
    const today = new Date();
    const last3Day = subDays(today, 3);
    const last7Day = subDays(today, 7);
    const last30Day = subDays(today, 30);

    // console.log(
    //   "today:",
    //   today,
    //   "last3Day:",
    //   last3Day,
    //   "last7Day:",
    //   last7Day,
    //   "last30Day:",
    //   last30Day
    // );

    setEndDate(format(new Date(), "MM-dd-yyyy"));
    if (day === 3) {
      setStartDate(format(new Date(last3Day), "MM-dd-yyyy"));
    } else if (day === 7) {
      setStartDate(format(new Date(last7Day), "MM-dd-yyyy"));
    } else if (day === 30) {
      setStartDate(format(new Date(last30Day), "MM-dd-yyyy"));
    } else {
      setStartDate(format(new Date(), "MM-dd-yyyy"));
    }
    refetch();
  };

  const handleOnchangeWareHouse = (e) => {
    // if (e.option !== "no-warehouse") {
    //   // setWarehouse(e.option);
    // } else {
    //   // setWarehouse("allWh");
    // }
    console.log("handle data:", e);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Dashboard"></Header>
            {/* Date Range, Warehouse Selection, and Buttons */}
            <div className="row pt-2">
  <div className="col-md-8 d-flex gap-2">
    <div className="d-flex align-items-center col-md-2">
      <b>Date:</b>
      <DatePicker
        selected={new Date(startDate)}
        className="form-control me-2"
        onChange={(date) => setStartDate(format(date, "MM-dd-yyyy"))}
      />
    </div>
    <div className="d-flex align-items-center col-md-2">
      <b>To:</b>
      <DatePicker
        selected={new Date(endDate)}
        className="form-control"
        onChange={(date) => setEndDate(format(date, "MM-dd-yyyy"))}
      />
    </div>
    <div className="d-flex align-items-center ">
      <b>Warehouse:</b>
      <WareHouseDW
        id="warehouse"
        name="warehouse"
        className="col-md-4 ms-2"
        handleOnChange={handleOnchangeWareHouse}
      />
    </div>
  </div>
  <div className="col-md-4 mt-2 d-flex gap-1">
    <button className="btn btn-dark" onClick={() => handleSelectDay(0)}>Today</button>
    <button className="btn btn-dark" onClick={() => handleSelectDay(3)}>Last 3 Days</button>
    <button className="btn btn-dark" onClick={() => handleSelectDay(7)}>Last Week</button>
    <button className="btn btn-dark" onClick={() => handleSelectDay(30)}>Last 30 Days</button>
  </div>
</div>


            <div className="row">
              <div className="col-md-12">
                <div className="row pt-3 mb-3">
                  <div className="col-md-12">
                    <Card
                      className="bg-white text-dark h-80"
                      style={{ boxShadow: `rgba(0, 0, 0, 0.25) 0px 5px 15px` }}
                    >
                      <LineChart className="h-50" />
                    </Card>
                  </div>
                </div>
              </div>
              {/* <PieChart style={{ marginTop: `23px` }} /> */}
              {/* <div className="col-md-4 pt-3">
                <Card
                  className="bg-white text-dark mb-3"
                  style={{ boxShadow: `rgba(0, 0, 0, 0.25) 0px 5px 15px` }}
                >
                  <PieChart
                    sale={total?.sale}
                    cogs={total?.cogs}
                    style={{ marginTop: `20px` }}
                  />
                  <div className="row mt-4">
                    <div className="col-md-4">
                      <h2 className="text-center" style={{ fontSize: `16px` }}>
                        <small>Sale</small>
                        <br />
                        <b style={{ fontSize: `18px` }}>
                          {" "}
                          {total?.sale ? total.sale.toFixed(2) : 0}
                        </b>
                        <b style={{ fontSize: `18px` }}>{weekGRN.toFixed(2)}</b>
                      </h2>
                    </div>
                    <div className="col-md-4">
                      <h2 className="text-center" style={{ fontSize: `16px` }}>
                        <small>COGS</small>
                        <br />
                        <b style={{ fontSize: `18px` }}>
                          {total?.cogs.toFixed(2)}
                        </b>
                      </h2>
                    </div>
                    <div className="col-md-4">
                      <h2 className="text-center" style={{ fontSize: `16px` }}>
                        <small>Profit</small>
                        <br />
                        <b style={{ fontSize: `18px` }}>
                        {total?.cogs && total?.total
                              ? (
                                  total?.total -
                                  total?.cogs -
                                  total?.discount
                                ).toFixed(2)
                              : 0}
                        </b>
                      </h2>
                    </div>
                  </div>
                </Card>
              </div> */}
            </div>
            <div className="row pt-3">
              <div className="col-md-4">
                <Toast className="m-0 w-full" style={{ width: "100%" }}>
                  <Toast.Body>
                    <Icons.CashOutline> </Icons.CashOutline>
                    <strong className="ms-2 me-auto"> Today's Sales</strong>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="h6 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">
                            Gross Sales Total:{" "}
                          </span>
                        </p>
                        <p className="h6 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">
                            Discount Total:{" "}
                          </span>
                        </p>
                        <p className="h4 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">
                            Net Sale Total:{" "}
                          </span>
                        </p>
                        {/* <p className="h6 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">
                            COGS Total:{" "}
                          </span>
                        </p>
                        <p className="h6 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">
                            Profit Total:{" "}
                          </span>
                        </p> */}
                      </div>
                      <div>
                        <p className="h6 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {total?.total ? total.total.toFixed(2) : 0}
                            {/* <small> ৳</small> */}
                          </strong>
                        </p>
                        <p className="h6 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {total?.discount ? total?.discount : 0}
                            {/* <small> ৳</small> */}
                          </strong>
                        </p>
                        <p className="h4 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {parseFloat(
                              total?.total ? total.total.toFixed(2) : 0
                            ) -
                              parseFloat(total?.discount ? total?.discount : 0)}
                            {/* <small> ৳</small> */}
                          </strong>
                        </p>
                        {/* <p className="h6 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {total?.cogs ? total.cogs.toFixed(2) : 0}
                            <small> ৳</small>
                          </strong>
                        </p>
                        <p className="h6 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {total?.cogs && total?.total
                              ? (
                                  total?.total -
                                  total?.cogs -
                                  total?.discount
                                ).toFixed(2)
                              : 0}
                            <small> ৳</small>
                          </strong>
                        </p> */}
                      </div>
                    </div>
                    {/* <h5 className=" text-right">
                    </h5> */}
                  </Toast.Body>
                </Toast>
              </div>
              <div className="col-md-3">
                <Toast className="m-0 w-full" style={{ width: "100%" }}>
                  <Toast.Body>
                    <Icons.CashOutline> </Icons.CashOutline>
                    <strong className="ms-2 me-auto"> Payments Recieved</strong>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="mb-1">
                          <strong>Cash</strong>
                        </p>
                        <p className="mb-2 h5">
                          {total?.paidAmount ? total?.paidAmount?.cash : 0}৳
                        </p>
                        <p className="mb-1">
                          <strong>Card</strong>
                        </p>
                        {total?.paidAmount?.card ? (
                          Object.entries(total?.paidAmount?.card).map(
                            ([key, value]) =>
                              value > 0 && (
                                <p key={key} className="fw-normal mb-0">
                                  {key}:{" "}
                                  <strong className="text-end tw-bold">
                                    {value}৳
                                  </strong>
                                </p>
                              )
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        <p className="mb-1">
                          <strong>Point Used</strong>
                        </p>
                        <p className="mb-2 h5">
                          {total?.paidAmount ? total?.paidAmount?.point : 0}৳
                        </p>

                        <p className="mb-1">
                          <strong>MFS</strong>
                        </p>
                        {total?.paidAmount?.mfs ? (
                          Object.entries(total?.paidAmount?.mfs).map(
                            ([key, value]) =>
                              value > 0 && (
                                <p key={key} className="fw-normal mb-0">
                                  {key}:{" "}
                                  <strong className="text-end tw-bold">
                                    {value}৳
                                  </strong>
                                </p>
                              )
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/* <h5 className=" text-right">
                    </h5> */}
                  </Toast.Body>
                </Toast>
              </div>
              <div className="col-md-5">
                <div className="row gy-3">
                  <div className="col-6">
                    <Toast>
                      <Toast.Body>
                        <Icons.ShoppingBag> </Icons.ShoppingBag>
                        <strong className="ms-2 me-auto"> Bucket Size</strong>
                        <h2 className="text-center">
                          {(total?.total
                            ? total?.total / total?.count
                            : 0
                          )?.toFixed(2)}
                          {/* <small> ৳</small> */}
                        </h2>
                      </Toast.Body>
                    </Toast>
                  </div>
                  <div className="col-6">
                    <Toast>
                      <Toast.Body>
                        <Icons.UserGroup> </Icons.UserGroup>
                        <strong className="ms-2 me-auto">
                          {" "}
                          Foot Falls
                        </strong>{" "}
                        <h2 className="text-center">
                          {total && total.count}
                          <small> People</small>
                        </h2>
                      </Toast.Body>
                    </Toast>
                  </div>
                  <div className="col-6">
                    <Toast>
                      <Toast.Body>
                        <Icons.ReplyOutline className="ms-3" size={18} />
                        <strong className="ms-2 me-auto"> Vat</strong>
                        <h2 className="text-center">
                          {total && total?.vat?.toFixed(2)}
                          {/* <small> ৳</small> */}
                        </h2>
                      </Toast.Body>
                    </Toast>
                  </div>
                  <div className="col-6">
                    <Toast>
                      <Toast.Body>
                        <Icons.CashOutline> </Icons.CashOutline>
                        <strong className="ms-2 me-auto">Point Spent</strong>
                        <h2 className="text-center">
                          {pointSpent ? pointSpent?.spentPoint : 0}
                          {/* <small> ৳</small> */}
                        </h2>
                      </Toast.Body>
                    </Toast>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
