import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import Header from "../Common/Header/Header";
// import ProductSearch from "../Common/ProductSearch/ProductSearch";
import SideBar from "../Common/SideBar/SideBar";
import "./Purchase.css";
// import { useForm } from "react-hook-form";
// import * as Icons from "heroicons-react";
// import CsvImporter from "../Common/CsvImporter/CsvImporter";
import { notify } from "../Utility/Notify";
import { Toaster } from "react-hot-toast";
// import usePurchase from "../Hooks/usePurchase";
// import useRtvCarts from "../Hooks/useRtv";
import { hash, signInUser } from "../Utility/Auth";
import axios from "axios";
// import useInventory from "../Hooks/useInventory";
import SelectSupplier from "../Common/CustomSelect/SelectSupplier";
import WareHouseDWPurchase from "../Common/CustomSelect/WareHouseDWPurchase";
import { AiOutlineClose } from "react-icons/ai";
import { BsArchive, BsCheckSquare, BsSquare } from "react-icons/bs";

import {
  selectSupplier,
  selectWareHouse,
  selectUser,
  selectProducts,
  selectNote,
  resetRtv,
  selectCalculation,
} from "../../features/rtvSlice";
import { useNavigate } from "react-router-dom";
import { useAddRtvMutation } from "../../services/rtvApi";
import { apiUniqueErrHandle } from "../Utility/Utility";
import { useDispatch, useSelector } from "react-redux";
// import SelectPriceGrn from "../Common/CustomSelect/ScelectPriceGrn";
import useRtv from "../Hooks/useRtv";
import CsvPurchaseProduct from "../Common/CsvImporter/CsvPurchaseProduct";
import LoadingModal from "../Common/Modal/LoadingModal";
import SelectPurchaseAccount from "../Common/CustomSelect/SelectPurchaseAccount";
import SelectGrnAccount from "../Common/CustomSelect/SelectGrnAccount";
// import { usePurchaseSupplierAccountQuery } from "../../services/purchasApi";
import { useGrnBySupplierQuery } from "../../services/grnApi";
// import { ImEyeMinus } from "react-icons/im";

const PurchaseCreate = () => {
  let i = 1;
  let j = 1;
  let navigate = useNavigate();
  // const purchaseCart = JSON.parse(localStorage.getItem("rtvCart"));
  const [addRtv] = useAddRtvMutation();
  const [supplier, setSupplier] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [isPO, setIsPO] = useState(false);
  const [isGRN, setIsGRN] = useState(false);

  const [isShowPO, setIsShowPO] = useState(false);
  const [isShowGrn, setIsShowGrn] = useState(false);

  const [poInfo, setPoInfo] = useState({});
  const [grnInfo, setGrnInfo] = useState({});
  const [supplierId, setSupplierId] = useState("");
  // const [isFull, setIsFull] = useState(false);

  const dispatch = useDispatch();
  const rtvData = useSelector((state) => state.rtvReducer);
  // const [calcTotal, setCalcTotal] = useState([]);
  const user = signInUser();
  const [wh, setWh] = useState("");
  const [supplierProducts, setSupplierProducts] = useState([]);
  const [onShow, setOnShow] = useState(false);
  const handleClose = () => setOnShow(false);

  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";
  const [filteredProduct, setFilteredProduct] = useState(supplier.products || []);

  // console.log(rtvData);
  const { totalItems } = useRtv();

  const { totalItem, total } = totalItems(rtvData.products);

  // const { data: po, isSuccess: poIsSuccess, refetch: poRefetch } = usePurchaseSupplierAccountQuery(`${supplierId}`);
  const {
    data: grn,
    isSuccess: grnIsSuccess,
    refetch: grnRefetch,
  } = useGrnBySupplierQuery(`${supplierId}`);

  useEffect(() => {
    dispatch(selectCalculation({ totalItem, total }));
  }, [totalItem, total]);

  useEffect(() => {
    // poRefetch()
    grnRefetch();
  }, [supplierId]);

  // SUBMIT RTV
  const handleBillSubmit = async (e) => {
    // e.preventdefault();
    const response = await addRtv(rtvData);

    if (response) {
      // console.log(response);
      if (response?.error) {
        apiUniqueErrHandle(response);
        notify("Please Select All Fields", "error");
      } else {
        let newIn = [];
        // console.log(response?.data?.data?.products);
        if (response?.data?.data?.products?.length > 0) {
          response?.data?.data?.products?.map(async (pro) => {
            // const updateInventory = await updateInventoryOUTOnRTVIn(
            //   response?.data?.data?.products
            // );
            newIn = [
              ...newIn,
              {
                article_code: pro?.article_code,
                qty: pro?.qty,
                tp: pro?.tp,
                name: pro?.name,
              },
            ];
            // console.log(pro);
          });

          // const updateInventory = await updateInventoryOUTOnRTVIn(newIn);
          // console.log(updateInventory)
          notify(response?.data?.message, "success");
          dispatch(resetRtv());
          setSupplier({});
          return navigate("/rtv");
        }
      }
    }
  };

  // SELECT SUPPLIER
  const handleVendorChange = async (value) => {
    console.log(value.option);

    const result = await axios.get(`${BASE_URL}/supplier/${value.option}`);
    // setPurchase({ value.option });
    console.log('RTV DATA::>',result.data.productDetails);
    setSupplierProducts(result.data.productDetails);
    setFilteredProduct(result.data.productDetails);
    setSupplier(result.data);
    setSupplierId(value.option);
    dispatch(selectSupplier(result.data._id));
    dispatch(selectUser(user.id));
    dispatch(selectProducts([]));
    // poRefetch()
    grnRefetch();
    setIsPO(false);
    setIsGRN(false);
  };

  const handleOnchangeWareHouse = (e) => {
    setWh(e.option);
    dispatch(selectWareHouse(e.option));
  };

  // POS Cart

  const emptyCart = () => {
    if (window.confirm("Refresh Cart! Start New RTV?") === true) {
      dispatch(selectProducts([]));
    } else {
      console.log("Refresh Operation Cancelled by POSER");
    }
  };

  // console.log(purchaseView)

  const handleSelectItem = (id) => {
    // console.log(id);
    // console.log(supplier);
    let newList = [];
    const selected = supplier?.productDetails.find((p) => p.id === id);
    // console.log(selected);
    if (rtvData.products.length > 0) {
      const exist = rtvData?.products.find((p) => p.id === id);
      const rest = rtvData?.products.filter((p) => p.id !== id);
      if (exist) {
        // newList = [...rtvData.products];
        // dispatch(selectProducts(newList));
        notify("Products Already Selected", "error");
      } else {
        notify("Products is Selected", "success");
        newList = [
          ...rest,
          {
            id: selected?.id,
            article_code: selected?.article_code,
            tp: selected?.tp,
            newPrice: false,
            name: selected?.name,
            mrp: selected?.mrp,
            tax: 0,
            qty: 1,
            unit: selected?.unit,
            discount: 0,
            order: rtvData.products.length + 1,
          },
        ];
        dispatch(selectProducts(newList));
      }

      // console.log(rtvData.products, supplier.products);
    } else {
      newList = [
        {
          id: selected?.id,
          article_code: selected?.article_code,
          tp: selected?.tp,
          newPrice: false,
          name: selected?.name,
          mrp: selected?.mrp,
          tax: 0,
          qty: 1,
          unit: selected?.unit,
          discount: 0,
          order: rtvData.products.length + 1,
        },
      ];
      dispatch(selectProducts(newList));
    }
  };

  // HANDLE CUSTOM QTY
  const handleCustomQty = (e, id) => {
    const customQty = e.target.value !== "" ? e.target.value : 0;

    const item = rtvData.products.find((p) => p.id === id);
    const remain = rtvData.products.filter((p) => p.id !== id);
    if (item) {
      // let restItem = cartItems.filter((item) => item.article_code !== id);
      if (customQty >= 0) {
        const newGrn = [
          ...remain,
          {
            ...item,
            qty: customQty,
          },
        ];
        dispatch(selectProducts(newGrn));
        // setIsFull(false);
      }
    }
  };

  const removeFromGrn = (id) => {
    const remain = rtvData.products.filter((p) => p.id !== id);
    dispatch(selectProducts(remain));
    notify("Product Removed", "error");
    // setIsFull(false);
  };

  const handleSearch = (q) => {
    // console.log(q);
    if (q.length > 0) {
      const re = new RegExp(q, "i");
      const filtered = supplierProducts.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val === "string" && val.match(re)
        )
      );

      setFilteredProduct(filtered);
      // console.log("Original:", supplierProducts);
      // console.log("filter:", filtered);
    } else {
      setFilteredProduct(supplierProducts);
    }
  };
  const handleImportButton = async () => {
    let importProducts = [];
    let i = 1;
    // console.log("csv", csvData);
    setOnShow(true);
    if (csvData?.length > 0) {
      csvData.map(async (pro) => {
        // console.log(pro);
        if (pro?.article_code) {
          try {
            const details = await axios(
              `${BASE_URL}/product/pro-details/${pro.article_code}`
            );
            // console.log(details);
            if (details.status === 200) {
              const data = details.data;
              importProducts = [
                ...importProducts,
                {
                  id: data?.id,
                  article_code: data?.article_code,
                  tp: data?.tp > 0 ? data?.tp : 0,
                  mrp: data?.mrp > 0 ? data?.mrp : 0,
                  name: data?.name,
                  qty: pro?.qty,
                  tax: 0,
                  discount: 0,
                  order: pro?.order,
                },
              ];
              setOnShow(false);
            }
          } catch (err) {
            notify(err, "error");
          } finally {
            // console.log(importProducts);
            dispatch(selectProducts(importProducts));
            // if (importProducts?.length === csvData?.length) {
            // }
          }
        }
      });
    } else {
      setOnShow(false);
      notify("There is no products to import", "error");
    }
    // console.log(importProducts);
  };

  const handleDeSelectIsPO = () => {
    setIsPO(false);
    setIsShowPO(false);
  };
  const handleIsPO = () => {
    setIsPO(true);
    if (isGRN === true) {
      setIsGRN(false);
    }
  };
  const handleDeselectIsGRN = () => {
    setIsGRN(false);
    setIsShowGrn(false);
  };
  const handleIsGRN = () => {
    setIsGRN(true);
    if (isPO === true) {
      setIsPO(false);
    }
  };

  const handlePOChange = (e) => {
    // console.log(e);
    setPoInfo(e.po);
    setIsShowPO(true);
    setIsShowGrn(false);
    dispatch(selectProducts(e.po.products));
  };
  const handleGRNChange = (e) => {
    // console.log(e);
    setGrnInfo(e.grn);
    setIsShowGrn(true);
    setIsShowPO(false);
    dispatch(selectProducts(e.grn.products));
  };

  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10 mt-2">
            <Header title="Create New RTV"></Header>

            <Form className="pt-3">
              <div className="row">
                <div className="col-1">
                  <b>GRN </b>
                  <br />
                  {isGRN ? (
                    <BsCheckSquare onClick={() => handleDeselectIsGRN()} />
                  ) : (
                    <BsSquare onClick={() => handleIsGRN()} />
                  )}
                </div>
                <div className="col-5">
                  {/* <input type="hidden" {...register("_id")} /> */}
                  <Form.Group className="" controlId="warehouse">
                    <Form.Label>Warehouse</Form.Label>
                    <WareHouseDWPurchase
                      // {...setValue("warehouse", `${wh}`)}
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouse}
                      wh={wh !== "" ? wh : 0}
                      // {...register("warehouse")}
                    />
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="">
                    <Form.Label>Supplier</Form.Label>
                    <SelectSupplier
                      supplier_code={supplier._id}
                      // setPurchase={setPurchase}
                      handleOnchange={handleVendorChange}
                      // {...setValue("supplier", `${supplierProductId}`)}
                      // {...register("supplier_code", { required: true })}
                    ></SelectSupplier>
                  </Form.Group>
                </div>
                <div className="col-4 mb-3">
                  {isGRN ? (
                    <div className="col-12">
                      <Form.Group className="" controlId="warehouse">
                        <Form.Label>GRN NO</Form.Label>
                        <SelectGrnAccount
                          data={grn}
                          isSuccess={grnIsSuccess}
                          handleVendorChange={handleGRNChange}
                        ></SelectGrnAccount>
                      </Form.Group>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-8 mb-3">
                  <CsvPurchaseProduct
                    title="Supplier Product"
                    setCsvData={setCsvData}
                    handleImportButton={handleImportButton}
                  />
                </div>
                <div className="col-8 mb-3">
                  <div className="row">
                    {/* {
                      isPO ? <div className="col-12">
                        <Form.Group className="" controlId="warehouse">
                          <Form.Label>Purchase NO</Form.Label>
                          <SelectPurchaseAccount
                            data={po}
                            isSuccess={poIsSuccess}
                            handleVendorChange={handlePOChange}
                          ></SelectPurchaseAccount>
                        </Form.Group>
                      </div> : <></>
                    } */}
                  </div>
                </div>

                {/* <div className="col-6">
                  <Form.Group className="" {...register("attached_doc")}>
                    <Form.Label>Attached Documents</Form.Label>
                    <input type="file" className="from-control" name="" />
                  </Form.Group>
                </div> */}
                <div className="container">
                  <div className="row">
                    <div className="col-5">
                      <div className="card">
                        <div className="card-header">
                          Supplier Products List
                          <div className="">
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              placeholder="Search Product"
                              onChange={(e) => handleSearch(e.target.value)}
                            />
                          </div>
                        </div>
                        {filteredProduct?.length > 0 ? (
                          <Table
                            className="mt-3 table-responsive w-100"
                            style={{
                              overflowY: "scroll",
                              display: "block",
                              height: "200px",
                            }}
                          >
                            <thead>
                              <tr>
                                {/* <th>Code</th> */}
                                <th>#</th>
                                <th>Name</th>
                                <th>Stock</th>
                                <th className="px-4">TP</th>
                                <th className="px-4">MRP</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredProduct?.length > 0 ? (
                                filteredProduct?.map((item) => (
                                  <tr key={item?.article_code}>
                                    <td>{i++}</td>
                                    <td
                                      title={item?.article_code}
                                      className="text-warp"
                                    >
                                      {item?.name} - {item?.group?.name}
                                    </td>
                                    <td>{item?.stock?.currentQty}</td>
                                    <td className="px-4">{item?.tp}</td>
                                    <td className="px-4">{item?.mrp}</td>

                                    <td className="text-center">
                                      {/* <BsSquareHalf className="me-2" /> */}
                                      <BsArchive
                                        onClick={() =>
                                          handleSelectItem(item.id)
                                        }
                                      />
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <th colSpan={8} className="text-center">
                                    {" "}
                                    Please Select a Supplier
                                  </th>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        ) : (
                          <Table className="mt-3 table-responsive">
                            <thead>
                              <tr>
                                {/* <th>#</th> */}
                                <th>Code</th>
                                <th>Name</th>
                                <th>Stock </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th colSpan={8} className="text-center">
                                  {" "}
                                  Please Select a Supplier
                                </th>
                              </tr>
                            </tbody>
                          </Table>
                        )}
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="card">
                        <Table className="mt-3 ">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Code</th>
                              <th>Name</th>
                              <th>Quantity</th>
                              <th>TP</th>
                              <th>MRP</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rtvData?.products?.length > 0 ? (
                              rtvData?.products
                                .slice()
                                ?.sort((a, b) => b.order - a.order)
                                ?.map((item) => (
                                  <tr key={item?.id}>
                                    <th>{j++}</th>
                                    <td>{item?.article_code}</td>
                                    <td>{item?.name}</td>
                                    <td>
                                      <div className="input-group ">
                                        {/* quantity */}
                                        <input
                                          type="text"
                                          className="form-control quantity"
                                          width="60%"
                                          id={item?.id}
                                          onChange={(e) =>
                                            handleCustomQty(e, item?.id)
                                          }
                                          value={item?.qty}
                                          defaultValue={item?.qty}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      {/* tp */}
                                      <input
                                        type="text"
                                        disabled={
                                          !item?.newPrice ? "disabled" : ""
                                        }
                                        className="form-control quantity"
                                        width="60%"
                                        id={item?.id}
                                        // onChange={(e) =>
                                        //   handleCustomTp(e, item?.id)
                                        // }
                                        value={parseFloat(item?.tp).toFixed(2)}
                                        defaultValue={parseFloat(
                                          item?.tp
                                        ).toFixed(2)}
                                      />
                                    </td>
                                    <td style={{ width: "120px" }}>
                                      {/* mrp */}

                                      <input
                                        type="text"
                                        className="form-control quantity"
                                        width="60%"
                                        id={item?.id}
                                        // onChange={(e) =>
                                        //   handleCustomMrp(e, item?.id)
                                        // }
                                        value={item?.mrp}
                                        defaultValue={parseFloat(
                                          item?.mrp
                                        ).toFixed(2)}
                                      />
                                    </td>

                                    <td>
                                      {(
                                        parseFloat(item?.qty) *
                                        (parseFloat(item?.tp) +
                                          parseFloat(item?.tp) *
                                            (item?.tax
                                              ? parseFloat(item?.tax) / 100
                                              : 0))
                                      ).toFixed(2)}
                                      {/* {console.log(
                                typeof item.tp,
                                typeof item.mrp,
                                typeof item.qty,
                                typeof item.tax
                              )} */}
                                    </td>
                                    <td>
                                      {/* <BsSquareHalf className="me-2" /> */}
                                      <AiOutlineClose
                                        onClick={() => removeFromGrn(item.id)}
                                      />
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <tr>
                                <th colSpan={8} className="text-center">
                                  {" "}
                                  Please Select a Product to return
                                </th>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="" controlId="formBasicEmail">
                      <Form.Label>Note</Form.Label>
                      <textarea
                        onChange={(e) => dispatch(selectNote(e.target.value))}
                        type="text"
                        className="form-control"
                        placeholder="Note"
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>

              <Button
                variant="dark"
                className="float-end my-2 mt-2"
                type="button"
                onClick={(e) => handleBillSubmit(e)}
              >
                Submit
              </Button>
              <Button
                variant="dark"
                className="float-end my-2 mx-2 mt-2"
                type="button"
                onClick={emptyCart}
              >
                Reset Cart
              </Button>

              <Table className="bordered striped ">
                <thead>
                  <tr>
                    <th>Items: {totalItem}</th>
                    <th>Total: {total}</th>
                    <th>Round Total:{Math.round(total)}</th>
                  </tr>
                </thead>
              </Table>
            </Form>
          </div>
        </div>
        <LoadingModal
          onShow={onShow}
          title="Please Wait.."
          handleClose={handleClose}
        />
        <Toaster position="bottom-right" />
      </div>
    </div>
  );
};

export default PurchaseCreate;
